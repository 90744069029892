<template>
  <div class="clearfix store-locator">
    <div class="store-locator__interface">
      <select v-model="selectedState" class="store-locator__selector store-locator__selector--state">
        <option disabled value="">State</option>
        <option v-for="state in states" :value="state.state" :key="state.state">{{ state.state }}</option>
      </select>

      <select :disabled="selectedState === '' || loading" v-model="selectedLocationType" class="store-locator__selector store-locator__selector--location-type">
        <option disabled value="">Location Type</option>
        <option v-for="(type, index) in locationType" :value="type.type" :key="index">{{ type.name }}</option>
      </select>

      <select :disabled="cities.length === 0 || loading" v-model="selectedCity" class="store-locator__selector store-locator__selector--city">
        <option disabled value="">City</option>
        <option v-for="(city, index) in cities" :value="city" :key="index">{{ city }}</option>
      </select>
    </div>
    <div class="loader" v-if="loading">Loading...</div>
    <div class="store-locator__results" v-if="stores.length">
      <div class="store-locator__results-type" :class="['store-locator__results-type--' + selectedLocationType]"></div>
      <div class="store-locator__result" v-for="(store, index) in stores" :key="index">
        <div class="store-locator__result__name">
          <span>{{ store.name }}</span>
        </div>
        <div class="store-locator__result__address">
          <a :href="'https://www.google.com/maps/dir/' + store.address + ',' + store.city + ',' + store.state + ',' + store.zip" target="_blank">{{ store.address }}, {{ store.city }}, {{ store.state }} {{ store.zip }}</a>
        </div>
        <div class="store-locator__result__phone" v-if="store.phone">
          <a :href="'tel:' + store.phone">{{ store.phone }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import states from '../static/js/stores.json'

  // Base axios instance.
  const HTTP = axios.create({
    baseURL: '/static/js/'
  });

  export default {
    /*
     * Data.
     */
    data: function() {
      return {
        states: states.map(state => {
          return {
            state: state.state,
            offPremise: state.offPremise || '',
            onPremise: state.onPremise || '',
            data: {}
          }
        }),
        cities: [],
        stores: [],
        selectedState: '',
        selectedLocationType: '',
        selectedCity: '',
        loading: false,
      }
    },

    /*
     * Methods.
     */
    methods: {
      getState(stateAbv) {
        return this.states.find(state => state.state === (stateAbv || this.selectedState))
      },
      setIfSingleLocationTypes() {
        if (this.locationType.length <= 1) {
          this.selectedLocationType = this.locationType[0].type;
        }
      }
    },

    /*
     * Computed Properties.
     */
    computed: {
      locationType() {
        let types = [];
        if (this.selectedState && this.getState()) {
          if (this.getState().offPremise) {
            types.push({type: 'offPremise', name: 'Store'});
          }
          if (this.getState().onPremise) {
            types.push({type: 'onPremise', name: 'Bars & Restaurants'});
          }
        }
        return types;
      },

    },

    /*
     * Property Hooks.
     */
    watch: {
      selectedState(newState) {
        this.cities = [];
        this.selectedLocationType = '';
        this.selectedCity = '';
        if (newState === '') {
          // Do nothing.
          return null
        }
        else if (Object.keys(this.getState().data).length !== 0) {
          // State data already loaded.
          // Jiggle that watcher...
          Vue.nextTick().then(() => {
            this.setIfSingleLocationTypes()
          })
        }
        else {
          // Changes detected, load new state json asyncronusly.
          this.loading = true;
          let actions = this.locationType.map(type => {
            return new Promise((resolve, reject) => {
              HTTP.get(this.getState()[type.type]).then(response => {
                resolve({type: type.type, data: response});
              });
            });
          });
          let results = Promise.all(actions);
          results.then(responses => {
            // Process city data for each location type.
            responses.forEach(response => {
              this.getState(newState).data[response.type] = Object.entries(response.data.data[newState]).map(arr => {
                let city = new Object();
                if (arr[1].store_list) {
                  city[arr[0]] = arr[1];
                }
                return city;
              }).filter(arr => Object.keys(arr).length !== 0);
            });
            this.loading = false;
            this.setIfSingleLocationTypes();
          });

        }

        // State analytics.
        if (window.gtag) {
          gtag('event', 'State', {
            'event_category': 'Store Locator',
            'event_label': newState,
            'value': null
          });
        }

      },

      selectedLocationType(newType) {
        this.cities = [];
        this.selectedCity = '';
        if (newType === '') { return null }
        this.cities = this.getState().data[newType].map(city => Object.keys(city)[0]);
      },

      selectedCity(newCity) {
        this.stores = [];
        if (newCity === '') { return null }
        this.stores = this.getState().data[this.selectedLocationType].find(city => Object.keys(city)[0] === newCity)[newCity].store_list;

        // City analytics.
        if (window.gtag) {
          gtag('event', 'City', {
            'event_category': 'Store Locator',
            'event_label': newCity,
            'value': null
          });
        }
      }
    },

    /*
     * Lifecycle Hooks.
     */
    mounted: function () {
      // Instance available.
    }

  }
</script>

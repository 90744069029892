import AgeGate from 'agegate';

let options = {
  form: document.querySelector('form'),
  countries: false,
  expiry: Infinity,
  age: 21
}

function getCookieValue(a) {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

var inputs = document.querySelectorAll(".es-agegate input");
for (i = 0; i < inputs.length; i++) {
  inputs[i].addEventListener('keyup', function() {
    var charLimit = this.getAttribute('maxLength');
    if (this.value.length >= charLimit) {
      this.nextElementSibling.focus();
    }
  });
}

if (getCookieValue('old_enough') !== 'true') {
  document.body.classList.add('agegate-enabled');
  let gate = new AgeGate(options, (err) => {
    if (err)  {
      alert('your age is to young');
    }
    else {
      document.body.classList.remove('agegate-enabled');
      // rejiger the anchor.
      var gotoLink = window.location.hash;
      if (gotoLink) {
        window.location.hash = "";
        setTimeout(function(){ window.location.hash = gotoLink; }, 50);
      }
    }
  });
}

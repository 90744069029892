/*
 * JS for Analytics
 */

function callDynamicVar(str) {
  var var_arr = str.match(/\[([^\[\]])*\]/g),
      dyn_var = null;
  if (var_arr) {
    try {
      var_arr.forEach((v) => {
        dyn_var = dyn_var ? dyn_var[v.replace(/\[|\]/g, '')] : window[v.replace(/\[|\]/g, '')];
      })
    } catch (error) {
      dyn_var = window.location.pathname;
    }
  }
  return var_arr ? dyn_var : str;
}

function setInlineEvents(targetElements) {
  const eventTypes = ['click'];
  // Assign events.
  eventTypes.forEach((event) => {
    let filteredElements = targetElements.filter(function(el){
      // Return elements with exact match of type except for 'swipe' (need both)
      let elEvent = el.getAttribute('data-ga-event-on');
      return elEvent == event && !el.dataset.gaEventProcessed;
    });
    // Assign events to elements.
    filteredElements.forEach((el) => {
      el.addEventListener(event, () => {
        var ga_category = el.dataset.gaEventCategory,
            ga_action = el.dataset.gaEventAction,
            ga_label = el.dataset.gaEventLabel !== undefined ? callDynamicVar(el.dataset.gaEventLabel) : el.getAttribute('href');
        if (window.gtag && ga_category !== undefined && ga_action !== undefined) {
          gtag('event', ga_action, {
            'event_category': ga_category,
            'event_label': ga_label,
            'value': null
          });
          dataLayer.push({
            'event' : 'ga_event',
            'ga_event_category' : ga_category,
            'ga_event_action' : ga_action,
            'ga_event_label' : ga_label,
            'ga_event_value' : null,
            'ga_event_noninteraction' : false
          });
        }
      });
      el.dataset.gaEventProcessed = true;
    });
  });
}

// GA custom inline events.
setInlineEvents([...document.querySelectorAll('[data-ga-event-on]')]);
// create an observer instance to track dynamic GA inline events.
var body = document.querySelector('body'),
    observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (mutation.target != body) {
          setInlineEvents([...mutation.target.querySelectorAll('[data-ga-event-on]:not([data-ga-event-processed])')]);
        }
      });
    });
// Trigger observer with options.
observer.observe(body, { childList: true, subtree: true });


import $ from 'jquery'
import tingle from 'tingle.js'
import LazyLoad from 'vanilla-lazyload/dist/lazyload.js'
import MoveTo from 'moveto'
import 'intersection-observer';
import scrollama from 'scrollama'
import './_analytics'
import './_agegate'
import './_app'

/*
 * Main scripts.
 */

// Update menu links since we are on homepage.
[...document.querySelectorAll('.js-link')].forEach(el => {
    el.setAttribute('href', el.getAttribute('href').replace('/',''));
});

// Lazy load images.
new LazyLoad({
    elements_selector: ".lazy",
    to_webp: (process.env.NODE_ENV === 'production')
});

// // Menu Observers.
scrollama().setup({
    step: '.js-scroll'
})
.onStepEnter(obj => {
    document.querySelector('.js-link[href="#' + obj.element.id + '"]').classList.toggle('active', true);
})
.onStepExit(obj => {
    document.querySelector('.js-link[href="#' + obj.element.id + '"]').classList.toggle('active', false);
});

// // Animation Observers.
// scrollama().setup({
//     step: '.js-scroll',
//     once: true,
//     offset: 0.6
// })
// .onStepEnter(obj => {
//     obj.element.classList.toggle('is-viewed', true);
// });

// Menu Scroll to.
const moveTo = new MoveTo({tolerance: 150});
[...document.querySelectorAll('.js-link')].forEach(el => {
    moveTo.registerTrigger(el);
});

// Smooth Sailling scrolling
const smoothSailingEl = document.querySelector('#smooth-sailing-bg');
function scrollBg() {
    smoothSailingEl.style.backgroundPositionX = window.pageYOffset + "px";
}
window.addEventListener('scroll', scrollBg);

// Brand Pillars.
$('.es-pillars__pillar').each(function(){
    $(this).on('click', function() {
        if (!$(this).is('.active')) {
            $(this).addClass('active top').siblings().removeClass('active top');
        }
        else {
            $(this).removeClass('active');
        }
    })
});

// Recipes.
const modalRecipe = new tingle.modal({
    closeMethods: ['overlay', 'button', 'escape'],
    closeLabel: "",
    cssClass: ['es-recipes__modal']
});

$('.es-recipes__recipe').on('click', function(){
    let title = $(this).find('.es-recipes__recipe__title').html();
    let desc = $(this).find('.es-recipes__recipe__desc').html();
    let img = $(this).find('img').prop('outerHTML');

    modalRecipe.setContent(`<div class="es-recipes__modal__wrap">
                                ${img}
                                <h2 class="es-recipes__modal__title">${title}</h2>
                                <div class="es-recipes__modal__desc">${desc}</div>
                            </div>`);
    modalRecipe.open();
});

// Window loaded.
$(window).on('load', function() {
    $('body').addClass('loaded');
});


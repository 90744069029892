
import Vue from 'vue'
import App from '../_vue/locator.vue'

//  Locator app.
if (document.querySelector('#locator-app')) {
    new Vue({
    el: '#locator-app',
    render: h => h(App)
    })
}